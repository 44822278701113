import { Textography } from '@fluentsolar/shared-web/Textography';
import * as React from 'react';

export default function FourOhFour() {
  return (
    <>
      <h1>404 - Page Not Found</h1>
      <Textography>
        If you think something should be here, please email tech support.
      </Textography>
    </>
  );
}
